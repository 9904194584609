import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/icon/xrdca.png'
import {Helmet} from "react-helmet";
import btcIcon from '../../assets/images/icon/bitcoin.png'
import usdtIcon from '../../assets/images/icon/usdt.png'
import usdcIcon from '../../assets/images/icon/usdc.png'
import Button from '../../components/button';


XWinRDCA.propTypes = {
    
};

function XWinRDCA(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN Reverse Dollar Cost Averaging</title>
                <meta name="description" content='RDCA involves gradually selling Bitcoin over time, aiming to mitigate risk during a bear market. RDCA provides a disciplined and systematic approach to selling, helping to reduce the emotional impact of market volatility.'/>
            </Helmet>
            <div>
            {/* Change Product Img */}
            <PageTitle img={product_img} externalLink={"https://app.xwin.finance/app/strategydetailv2/0x5b36A673c7a01224b35b010DE0281c979C74f1A3/chainId=56"} heading='Reverse Dollar Cost Average (RDCA)' title='RDCA' />
            </div>
            <section className="blog-details">
                <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={btcIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdtIcon} alt="" />}
                                className="p-2" title='BSC' path='https://app.xwin.finance/app/strategydetailv2/0x5b36A673c7a01224b35b010DE0281c979C74f1A3/chainId=56' />
                                <div className="p-2" >BTC/USDT</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={btcIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdcIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/strategydetailv2/0x4917B129c45709b0FE8aD0B0580D70b244e99184/chainId=42161' />
                                <div className="p-2" >BTC/USDC</div>
                            </div>
                        </div>
                    </div>  
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={btcIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdcIcon} alt="" />}
                                className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0xBD94FA1DFbEC182C1252F44fe87F514074c76C44/chainId=137' />
                                <div className="p-2" >BTC/USDC</div>
                            </div>
                        </div>
                    </div>
                </div>               
                
                
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                    
                    <div className="content p-2">
                    
                        <h5>TRADING STRATEGY DESCRIPTION</h5>

                        <ul>
                            <p>RDCA involves gradually selling Bitcoin over time, aiming to mitigate risk during a bear market. RDCA provides a disciplined and systematic approach to selling, helping to reduce the emotional impact of market volatility. By selling at regular intervals, investors avoid the pressure of timing the market and potentially missing out on selling opportunities during short-term price rallies. The overall objective of RDCA is accumulating fiat currency during a bear market.</p>
                            <br></br>
                            <p><b>Objective:</b></p>
                            <p>• Gradually sell Bitcoin holdings during a bear market and reinvest proceeds into Bitcoin at lower prices.</p>
                            <br></br>
                            <p><b>Mechanism:</b></p>
                            <p>• Divide Bitcoin holdings into equal portions and sell portions at regular intervals to accumulate cash reserves. When Bitcoin prices reach lower levels or when market sentiment improves, reinvest cash reserves to buy back Bitcoin. This approach allows investors to capitalize on market downturns to accumulate cash and buy back more Bitcoin at discounted prices.</p>
                            <br></br>
                            <p><b>Benefits:</b></p>
                            <p>• Provides a disciplined approach to selling Bitcoin during bear markets, reduces the risk of selling all holdings at a single low price point, and allows investors to take advantage of lower prices to increase Bitcoin holdings.</p>
                            <br></br>
                            <p><b>Considerations:</b></p>
                            <p>• Requires careful planning and discipline to execute the selling and reinvestment process effectively, involves the risk of selling too early or too late, and may result in missed opportunities if market timing is incorrect.</p>
                        </ul>
                        <div className="box-image">
                        
                        </div>
                    </div>

                    </div>
                </div>
                
                </div>
                </div>
            </section>
        </div>
    );
}

export default XWinRDCA;