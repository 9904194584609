import React , {useState, useEffect} from 'react';
import {withStyles, Slider } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'chart.js';



import 'chart.js/auto';

const SliderOptimiser = () => {

      const [data1, setData1] = useState({
        labels: ['BTC Staking', 'ADA Staking', 'xSCA','xDCA','xUSDT'],
        datasets: [
          {
             data:[20, 30, 25, 15, 10],
            backgroundColor: ['#536DFE','#EE266D','#E9B55F','#63C5B5','#AE1ECC','rgba(64, 224, 208,  0.8)','rgba(0, 0, 0,  0.8)','rgba(255, 0, 0,  0.8)','rgba(128, 128, 128,  0.8)'],
          }
        
        ],

      });
      const chartData = {
        labels: ['xBTC', 'xADA', 'xSCA','xDCA','xUSDT'],
        backgroundColor: ['#536DFE','#EE266D','#E9B55F','#63C5B5','#AE1ECC','rgba(64, 224, 208,  0.8)','rgba(0, 0, 0,  0.8)','rgba(255, 0, 0,  0.8)','rgba(128, 128, 128,  0.8)'],
        hoverBackgroundColor: ['#536DFE','#EE266D','#E9B55F','#63C5B5','#AE1ECC', 'rgba(64, 224, 208,  0.8)','rgba(0, 0, 0,  0.8)','rgba(255, 0, 0,  0.8)','rgba(128, 128, 128,  0.8)'],
      }

      const {labels,backgroundColor,hoverBackgroundColor}=chartData;
      const [riskLevel, setRiskLevel] = useState(0);

      const updateChartData = () => {
        let newData;
      
        switch (riskLevel) {
          case 0:
            newData = {
                labels: labels,
                datasets: [
                {
                  data:[3, 2, 35, 5, 55],
                  backgroundColor: backgroundColor,
                  hoverBackgroundColor: hoverBackgroundColor,
                  // borderColor: 'gray',

                }
              ]
            };
            break;
            case 10:
                newData = {
                    labels: labels,
                    datasets: [
                    {
                        data:[5, 3, 45, 2, 45],
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                  ]
                };
                break;
                
          case 20:
            newData = {
                labels: labels,
                datasets: [
                {
                    data:[10, 5, 40, 5, 40],
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor
                }
              ]
            };
            break;
            case 30:
                newData = {
                    labels: labels,
                    datasets: [
                    {
                        data:[15, 5, 40, 5, 35],
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                  ]
                };
                break;
                case 40:
                    newData = {
                        labels: labels,
                        datasets: [
                        {
                            data:[15, 5, 35, 10, 35],
                            backgroundColor: backgroundColor,
                            hoverBackgroundColor: hoverBackgroundColor
                        }
                      ]
                    };
                    break;    
            case 50:
                newData = {
                    labels: labels,
                    datasets: [
                    {
                        data:[20, 5, 30, 15, 30],
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                  ]
                };
                break; 
                case 60:
                    newData = {
                        labels: labels,
                        datasets: [
                        {
                            data:[25, 10, 20, 20, 25],
                            backgroundColor: backgroundColor,
                            hoverBackgroundColor: hoverBackgroundColor
                        }
                      ]
                    };
                    break;
            case 70:
            newData = {
                labels: labels,
                datasets: [
                {
                    data:[25, 15, 15, 25, 20],
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor
                }
              ]
            };
            break;
            case 80:
                newData = {
                    labels: labels,
                    datasets: [
                    {
                        data:[25, 20, 12, 25, 18],
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                  ]
                };
            break;
            case 90:
                newData = {
                    labels: labels,
                    datasets: [
                    {
                        data:[30, 10, 10, 40, 10],
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                  ]
                };
                break;              
          case 100:
            newData = {
                labels: labels,
                datasets: [
                {
                    data:[30, 20, 7, 40, 3],
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor
                }
              ]
            };
            break;
          default:
            newData = {
                labels: labels,
                datasets: [
                {
                    data: [45,2,15,3,5,4,10,15,1],
                    backgroundColor: backgroundColor,
                    hoverBackgroundColor: hoverBackgroundColor
                }
              ]
            };
        }
      
        setData1(newData);
      };
      
      
      useEffect(() => {
        updateChartData();
      }, [riskLevel]);
      
      
      const useStyles = makeStyles({
        root: {
          maxWidth: 600,
          
        },
    
        active: {},
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      });

      const classes = useStyles();
 
    
  return (
    <div class="p-2" style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", }}>
            <h6 style={{color:"black"}}>Low Risk</h6>
            <h6 style={{color:"black"}}>High Risk</h6>
          </div>  

        <div className={classes.root} style={{ display:"flex",justifyContent:"center", maxWidth:"600px"}}>

        <Slider 
            valueLabelDisplay="on"
            marks
            value={riskLevel}
            valueLabelColor="black"
            step={10}
            min={0}
            max={100}
            style={{
                marginTop:"20px",
                marginBottom:"20px",
                background: "linear-gradient(to '#536DFE','#EE266D','#E9B55F','#63C5B5','#AE1ECC')",
                backgroundColor:"success",
                borderRadius:"5px",
                valueLabel: {
                  color: 'black',
                },

            }}
    
            aria-labelledby="range-slider"
            onChange={(e, value) => setRiskLevel(value)}
        />
        </div>
        <Doughnut 
        data={data1}
        // style={{marginLeft:"-20px"}}
        options={{
            plugins: {
              legend: {
                position:"bottom",
                labels: {
                    padding:20,


                  font: {
                    size: 14,
                  },
                  color:'#230b59',
                },
                borderColor: 'black',

          
              }
            }
          }}
           />  
           <br/>
        <p style={{color:"black"}} class="text"><i>*** This is only for illustration purpose. Please visit  <a href={"https://app.xwin.finance/app/allocation"}>xWIN Robo Advisor</a> for real allocation </i></p>  
    </div>
  )
}

export default SliderOptimiser