//Pancake swap
//Apeswap
//Biswap
//Chainlink

//venus finance
//ola finnace
//


//coming soon
//uniswap
//aave

import apeswap from "../images/icon/apeswap-icon.png"
import pancake from "../images/icon/pancakeswap-icon.png"
import chainlink from "../images/icon/chainlink-icon.png"
import venus from "../images/icon/venus-icon.png"
import biswap from "../images/icon/biswap-icon.png"
import ola from "../images/icon/ola-icon.png"
import uniswap from "../images/icon/uniswap-icon.png"
import Arbitrum from "../images/icon/arb-logo.png"
import aave from "../images/icon/aave-logo.png"
import nilssonLogo from "../images/xwin-partners/nilsonhedge logo.jpg"
import mamaLogo from "../images/xwin-partners/mamalogo.jpg"
import tradingStrategyLogo from "../images/xwin-partners/trading.png"
import futureMoneyLogo from "../images/xwin-partners/FutureMoneyLogo.jpg";
import moonbitLogo from "../images/xwin-partners/moonbit.png"




const dataIntergrations = [{
        id: 1,
        icon: pancake,
        title: 'PancakeSwap',
        active: " ",
        link: "https://pancakeswap.finance/"
    },
    {
        id: 2,
        icon: chainlink,
        title: 'ChainLink',
        active: " ",
        link: "https://chain.link/"

    },
    {
        id: 3,
        icon: venus,
        title: 'Venus',
        active: " ",
        link: "https://venus.io/"
    },
    {
        id: 4,
        icon: ola,
        title: 'Ola Finance',
        active: " ",
        link: "https://ola.finance/"
    },
    {
        id: 5,
        icon: apeswap,
        title: 'ApeSwap',
        active: " ",
        link: "https://apeswap.finance/"
    },
    {
        id: 6,
        icon: biswap,
        title: 'Biswap',
        active: " ",
        link: "https://biswap.org/"
    },



]

const dataIntergrations2 = [{
        id: 1,
        icon: uniswap,
        title: 'Uniswap',
        active: " ",
        link: "https://uniswap.org/"
    },
    {
        id: 2,
        icon: Arbitrum,
        title: 'Arbitrum',
        active: " ",
        link: "#"
    },
    {
        id: 3,
        icon: aave,
        title: 'AAVE',
        active: " ",
        link: "https://app.aave.com/"
    },


]

const partners = [
	{
		id: 1,
		icon: mamaLogo,
		title: "Mama Asset Managers Association",
		active: " ",
		link: "https://www.mama.global/",
	},
	{
		id: 2,
		icon: tradingStrategyLogo,
		title: "Trading Strategy",
		active: " ",
		link: "https://tradingstrategy.ai/",
	},
	{
		id: 3,
		icon: nilssonLogo,
		title: "NilssonHedge",
		active: " ",
		link: "https://nilssonhedge.com/",
	},
	{
		id: 4,
		icon: futureMoneyLogo,
		title: "Future Money",
		active: " ",
		link: "https://bestcryptoindexfund.eu/",
	},
	{
		id: 5,
		icon: moonbitLogo,
		title: "Moonbit",
		active: " ",
		link: "https://www.moonbit.ai/",
	},
];

export default { dataIntergrations, dataIntergrations2, partners };