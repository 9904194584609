import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import img from '../../assets/images/logo/logo.png'
import img1 from '../../assets/images/logo/logo.png'


function Footer(props) {

    const [productLink] = useState([
        {
            title: 'What is xWIN Finance',
            path: '/Xwinfinance'
        },
        {
            title: 'Robo Advisor',
            path: '/crypto-robo-advisor'
        },
        {
            title: 'Dollar Cost Average',
            path: '/dollar-cost-avarage'
        },
        {
            title: 'Reverse DCA',
            path: '/reverse-dollar-cost-average'
        },
        {
            title: 'Stablecoin Alpha',
            path: '/stablecoin-alpha'
        },
        {
            title: 'Dollar Zanmai',
            path: '/dollar-zanmai'
        },
        {
            title: 'Interest Rate Trading',
            path: '/bitcoin-trading-strategy'
        },
        {
            title: 'Index',
            path: '/index-fund'
        },
        {
            title: 'Lending Vault',
            path: '/crypto-staking'
        },
    ]);
    

    const [listSocial] = useState([
        {
            icon: 'icon-facebook-f',
            path: 'https://www.facebook.com/xwinfinanceofficial'
        },
        {
            icon: 'icon-youtube',
            path: 'https://www.youtube.com/@xWINCryptoTV'
        },
        {
            icon: 'icon-twitter',
            path: 'https://twitter.com/xwinfinance'
        },
    ])
    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer style-2">
            <div className="container">
                <div className="footer__main">
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                    <div className="info p-2">
                        <Link to="/" className="logo light">
                        <img src={img} alt="" />
                        </Link>
                        <Link to="/" className="logo dark">
                        <img src={img1} alt="" />
                        </Link>
                        <h6>Let's talk! 🤙</h6>
                        <ul className="list">
                        <li><p>admin@xwin.com.my</p></li>
                        <li>
                            <p>
                            3-5-3, Tower 3, UOA Business Park, No. 1, Jalan Pengaturcara U1/51A, Seksyen 1, Shah Alam, Selangor 40150, MY


                            </p>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                    <div className="widget p-2">
                        <div className="widget-link">
                        {/* <h6 className="title">Strategies</h6> */}
                        <ul>
                            {
                                productLink.map((data,idx) => (
                                    <li key={idx}><Link to={data.path}>{data.title}</Link></li>
                                ))
                            }
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-12">
                    <div className="footer-contact p-2">
                        <ul className="list-social">
                            {
                                listSocial.map((data,idx) => (
                                    <li key={idx}>
                                        <a href={data.path}><span className={data.icon}></span></a>
                                    </li>
                                    

                                ))
                            }
                               <li >
                                        <a href='https://www.t.me/xwinfinance'>
                                             <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512" fill="#777e90">
                                                <title></title>
                                            <g id="icomoon-ignore" >
                                            </g>
                                            <path d="M256 0c-141.4 0-256 114.6-256 256s114.6 256 256 256 256-114.6 256-256-114.6-256-256-256zM381.8 175.5l-42 197.9c-2.9 14.1-11.4 17.4-23.2 10.9l-64-47.3-30.7 29.9c-3.6 3.5-6.4 6.4-12.8 6.4-8.3 0-6.9-3.1-9.7-11l-21.8-71.6-63.3-19.7c-13.7-4.2-13.8-13.6 3.1-20.3l246.6-95.2c11.2-5.1 22.1 2.7 17.8 20z"></path>
                                            </svg>
                                        </a>
                                </li>
              
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="footer__bottom">
                <p>
                    ©2022 xWIN Technology Sdn. Bhd All rights reserved. Terms of Service | Privacy
                    Terms
                </p>
                </div>
            </div>

            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;