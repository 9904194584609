import usdtIcon from '../assets/images/coin/usdt.png'
import usdcIcon from '../assets/images/coin/usdc.png'
const menus = [
    {
        id: 1,
        external:false,
        name: 'Introduction',
        links: '/Xwinfinance',
        
    },
    {
        id: 2,
        external:false,
        name: 'Robo Advisor',
        links: '/crypto-robo-advisor',
    
    },
    {
        id: 3,
        external:false,
        name: 'Strategies',
        links: '#',
        namesub: [
            {
                id: 2,
                sub: 'Dollar Cost Average',
                links: '/dollar-cost-avarage'
            },
            {
                id: 7,
                sub: 'Reverse DCA',
                links: '/reverse-dollar-cost-average'
            },
            {
                id: 3,
                sub: 'Stable Coin Alpha',
                links: '/stablecoin-alpha'
            },
            {
                id: 5,
                sub: 'Dollar Zanmai',
                links: '/dollar-zanmai'
            },
            {
                id: 1,
                sub: 'Interest Rate Trading',
                links: '/bitcoin-trading-strategy'
            },
            {
                id: 6,
                sub: 'Index',
                links: '/index-fund'
            },
            {
                id: 4,
                sub: 'Lending Vault',
                links: '/crypto-staking'
            },
        ],
    },
    {
        id: 4,
        external:true,
        name: 'Bridge',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'USDT - USDC.e',
                icon1: usdtIcon,
                icon2: usdcIcon,
                links: 'https://multitx.bungee.exchange/?fromChainId=56&fromTokenAddress=0x55d398326f99059ff775485246999027b3197955&toChainId=42161&toTokenAddress=0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
            },
            {
                id: 2,
                sub: 'USDC - USDC.e',
                icon1: usdcIcon,
                icon2: usdcIcon,
                links: 'https://multitx.bungee.exchange/?fromChainId=56&fromTokenAddress=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&toChainId=42161&toTokenAddress=0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'
            },
        ],
    },
    {
        id: 4,
        external:true, 
        name: 'Legacy',
        links: 'https://v1.xwin.finance/app/dashboard',
    },
    {
        id: 5,
        external:true,
        name: "Docs",
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Docs',
                links: "https://doc.xwin.finance/master",
            },
            {
                id: 2,
                sub: 'Developer Docs',
                links: "https://developer.xwin.finance",
            },
        ],
    },
]

export default menus;