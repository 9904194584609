import React from "react";
import {
    Dialog,
    DialogContent,
    CardHeader,
    IconButton,
    Typography
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import ReactPlayer from 'react-player/lazy'


Annoucement.propTypes = {
    
};

function Annoucement(props) {
    const { show, close } = props;

    return (
        <Dialog
            open={show}
            aria-labelledby="form-dialog-title"
            fullWidth={false}
            fullScreen={false}
            maxWidth = {'md'}
            style={{borderRadius:"20px"}}
        >                        
           <CardHeader
                title={<h6>xWIN Finance v2 Launched!</h6>}
                style={{backgroundColor:"#536dfe"}}
                action={                
                    <IconButton 
                    onClick={close}
                    aria-label="settings">
                    <CancelIcon />
                    </IconButton>
                }
            />
                <ReactPlayer
                            url='https://xwinfinance.s3.amazonaws.com/xWin+Finance+V2.mp4#t=1'
                            width='100%'
                            height='100%'
                            controls={true}
                            playing
                            loop
                            muted
                        />
                <DialogContent style={{color:"darkgray"}} >
                    <Typography
                    variant="caption"
                    >
                    xWIN is proudly to present xWIN Finance v2 (BNB version). 
                    xWin Finance V2 is an enhanced version of DEFI platform on the Binance Smart Chain and Ethereum network 
                    that uses advanced algorithms and optimization techniques to help you create and manage your portfolio based on your risk tolerance 
                    and investment goals
                    </Typography>
                </DialogContent>

        </Dialog>
    );
}

export default Annoucement;