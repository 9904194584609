import React , {useState} from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../components/pagetitle/index';
import img1 from '../assets/images/products/WASR.png'
import img2 from '../assets/images/products/Optimiser.png'
import product_img from "../../src/assets/images/logo/favicon.png"
import ReactPlayer from 'react-player';
import {Helmet} from "react-helmet";



    const About = () => {
        const [isOpen, setOpen] = useState(false);
        const [youtubeId, setYoutubeId]= useState("");

    return (
        <div>
            <Helmet>
                <title>xWIN Crypto Robo Advisor</title>
                <meta name="description" content="The xWIN Robo-Advisor creates a cryptocurrency portfolio tailored to your age and risk tolerance. It will optimize allocation among selected native tokens such as Bitcoin, Ethereum, and ADA, as well as xWIN's own strategy tokens" />
            </Helmet>
        <div>
        <PageTitle externalLink={"https://app.xwin.finance/app/allocation"} img={product_img} heading='Robo Advisor' title='Robo' />
        </div>
        
        <section className="blog-details">
            <div className="container">
            <div className="d-flex p-2 justify-content-center col-xl-12">
                        <ReactPlayer
                            url='https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2'
                            width='100%'
                            height='100%'
                            controls={true}
                            loop
                        />
                    </div>
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">

                    <div className="content p-2">
                        <h5>What is xWIN Robo Advisor?</h5>
                        <p>
                

                        The objective of this xWIN Robo Advisor is to allow individual investors or fund managers to create their own decentralized fund based on various market factors or personal risk parameters. 
                        xWIN Robo Advisor is built with an in-house optimizer engine, which allows investors to create the optimal portfolio through mean-variance efficient or weighted average Sharpe Ratio. 





                        </p>

                        <div className="box-image">
                        
                        </div>

                    

                        <h5>What allocation approaches are available?</h5>
                        <p>
                        Individual investors provide age and risk tolerance parameters and choose the allocation model. xWIN engines provides two approaches: <br/><br/>
                        1. Mean Variance Optimization <br/><br/>
                        2. Weighted Average Sharpe Ratio <br/><br/>
                        xWIN platform continuously capturing the all the related market data of all the universal assets supported including our own strategies. 
                        The data will be analyzed and transformed into statistical data such as expected returns, volatilities, covariance, and Sharpe ratio.
                        These data are important parameters to achieve the optimal asset allocation for our clients


                        </p>
                       

                        <div className="box-image">
                        
                        </div>

                        <h5>Mean-Variance Optimization</h5>
                        <p>
                        In portfolio management, mean-variance analysis is a method used to determine the optimal allocation of assets in a portfolio using the factors provided including risk tolerance and age. 
                        It involves considering the expected return and risk (variance) of each asset in the portfolio and finding the combination that maximizes the expected return for a given level of risk.
                        The mean-variance analysis allows investors to benefit as it enables better decision-making and improved performance.
                        Mean-variance analysis helps investors create the optimal combination of assets by maximizing the expected returns for a given level of risk.
                        Putting all the investment into one asset is very risky and may not return as much. Diversification allows investors to invest different portions of their account into many different strategies which reduce risk. 
                        The xWIN optimizer creates the best portfolio for its investor by creating not only the best tokens for the investor but also how much allocation for each asset using the inputs from the user.


                        </p>


                        <div className="box-image">
                            
                        </div>

                        <h5>Weighted-Average Sharpe Ratio</h5>
                        <p>
                        The Weighted Average Sharpe Ratio uses the Sharpe Ratio, the measure of risk-adjusted return.
                        The risk-adjusted return considers the risk as well as the risk-free rate to see how much return a token is compared to the safest option like stable coin staking. 
                        The approach will first allocate the major allocation into stable coin staking asset based on the risk tolerances as parameter.
                        Finally, the remaining weights will be allocated based on the Sharpe ratio weight of each risky token. 

                        </p>

                        <div className="box-image">
                            
                        </div>

                        <div className="img">
                        <img src={img1} alt="" />
                        </div>
                        <p>
                        W = Weighted average
                        <br/><br/>
                        n = Number of tokens
                        <br/><br/>
                        wi = weight applied to X values
                        <br/><br/>
                        Xi = Sharpe ratio value
                        <br/><br/>


                        </p>

                        <div className="box-image">
                            
                        </div>

                        <h5>How To Use xWIN Robo Advisor?</h5>
                        <p>
                        There are 2 steps to run xWIN Robo Advisor.<br/><br/>
                        1. Run Optimizer to get the tokens allocation as show in the screen below<br/><br/>
                        2 .Click build and create your vault by signing your transaction. This will deploy the xWIN fundv2 smart contract to the network. You will be able to access to the private fund under My Menu.
                        </p>

                        <div className="img">
                        <img src={img2} alt="" />
                        </div>

            
    
                    </div>

                    </div>
                </div>
                
                </div>
            </div>
            <ModalVideo channel='custom' autoplay isOpen={isOpen} url="https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2" videoId={youtubeId} onClose={() => setOpen(false)} />
        </section>

    </div>
    )
    }

    export default About