import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/icon/xsca.btc.png'
import ReactPlayer from 'react-player';
import {Helmet} from "react-helmet";
import usdtIcon from '../../assets/images/icon/usdt.png'
import usdcIcon from '../../assets/images/icon/usdc.png'
import btcIcon from '../../assets/images/icon/bitcoin.png'
import gmxIcon from '../../assets/images/icon/gmx.png'
import polygonIcon from '../../assets/images/icon/polygon.png'
import arbIcon from '../../assets/images/icon/arb-logo.png'
import Button from '../../components/button';


XWinSCA.propTypes = {
    
};

function XWinSCA(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN unique crypto staking</title>
                <meta name="description" content="Lending Vault allows buy the token with earning interests capability. You can also incorporate that strategy token into your own portfolio and rebalance it automatically." />
            </Helmet>

        <PageTitle externalLink={"https://app.xwin.finance/app/strategydetailv2/0x0a652784DF3f8Abde85dAEeee77D1EA97f5c5B24/chainId=56"} img={product_img} heading='Stable Coin Alpha (SCA)' title='SCA' />

        <section className="blog-details">
            <div className="container">
                <div className="row">
                    <div className="d-flex p-2 justify-content-center col-xl-6 col-md-6">
                            <ReactPlayer
                                url='https://xwinfinance.s3.amazonaws.com/xWIN-SCA.mp4#t=1'
                                width='100%'
                                height='100%'
                                controls={true}
                                loop
                            />
                    </div>
                    <div className="col-xl-6 col-md-6 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                title='BNB' path='https://app.xwin.finance/app/strategydetailv2/0x0a652784DF3f8Abde85dAEeee77D1EA97f5c5B24/chainId=56' />                               
                                <div className="p-2" >USDT/BTC</div>
                            </div>
                            
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0xb26698F9764833BE909D04Aed7Bb6832117288e3/chainId=137' />
                                <div className="p-2" >USDC/BTC</div>
                            </div>
                            
                        </div>
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={gmxIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/strategydetailv2/0xa3960cB557872D5F68ef06dc63a14b7E6b64d552/chainId=42161' />
                                <div className="p-2" >USDC/BTC</div>
                            </div>
                            <div className="p-2" >
                                <Button 
                                    image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                    image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                    className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/strategydetailv2/0x4c4CBc2Aa5C477747B32530d2eDD83254f61fa71/chainId=42161' />
                                
                                <div className="p-2" >USDC/GMX</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                   
                    <div className="content p-2">
                        <p>
                        This strategy ensures that the principal amount is not lost. It is an alternative strategy to just staking USDC. Therefore, all the assets in this case GMX are bought through the interest rate made by the stable coin. It aims to create a positive return through the capital gains from the asset bought using the interest rate.
                        </p>

                        <div className="box-image">
                        
                        </div>

                    

                        <h5>TRADING STRATEGY DESCRIPTION</h5>
                        <p>
                        This strategy is used to ensure that the initial amount is not lost. To achieve this, it buys assets like BTC using the interest earned by stablecoin like USDT. 
                        Stablecoins do not fluctuate as they mirror the dollar. USDT’s interest rate depends on the market’s supply and demand and can range from 30% to 2% each day. 
                        The strategy takes the daily earned interest and buys assets like BTC daily. The strategy does not sell the assets and looks at the long-term growth of those assets. 
                        Unlike just staking, SCA holds some assets like BTC in the hopes that it would grow.




                        </p>
                       

                        <div className="box-image">
                        
                        </div>

                        <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                        <ul >
                            <p>This strategy is suitable for investors who:</p>
                            <p>• have low risk appetite</p>
                            <p>• are seeking for long capital growth</p>
                            <p>• are seeking to make investment slowly and risk-free.</p>
                        </ul>

                        <div className="box-image">
                            
                        </div>
      
                    </div>

                    
                    </div>
                </div>
                
                </div>
            </div>
        </section>        
    </div>
    );
}

export default XWinSCA;