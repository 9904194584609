import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {Link} from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';


PageTitle.propTypes = {
    title: PropTypes.string,
};

function PageTitle(props) {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {title , heading, img, externalLink} = props;

    return (
        <section className="page-title">
            <div className="container">
                <div className="row">
                <div className="col-md-6" style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    {!isMobile && 
                    <img src={img} style={{ maxHeight:"100px", paddingRight:"30px"}} alt="" />
                    }
                    <a href={externalLink} target='_blank'>
                    <h5 style={{color:"#230b59"}}>{heading}</h5></a>
                </div>
                <div className="col-md-6">
                    <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><p className="fs-18">/</p></li>
                    <li><p className="fs-18">{title}</p></li>
                    </ul>
                </div>
                </div>
            </div>
        </section>
    );
}

export default PageTitle;