import React from 'react';
import PropTypes from 'prop-types';


import './styles.scss';


Button.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
};


function Button(props) {
    const {title , path, image1, image2, image3 } = props;
    return (
        <a style={{justifyContent:"center", textAlign:"center"}} href={path} target="_blank" rel="noopener noreferrer external" className="btn-action" >
            <span>{image1}</span>
            <span>{image2}</span>
            <span>{image3}</span>
            <span className="p-1">{title}</span>
        </a>



    );
}

export default Button;