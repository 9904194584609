import React, { useState, useEffect, useContext } from 'react'
import Banner03 from '../components/banner/Banner03'
import Services02 from '../components/services/Services02'
import Trading from '../components/trading/Trading'
import Counter from '../components/counter/Counter'
import Join from '../components/join/Join'
import dataServices2 from '../assets/fake-data/data-services-2'
import dataTrading from '../assets/fake-data/data-trading'
import About01 from '../components/about/About01'
import Faq from './Faq'
import Annoucement from './Annoucement'
import ReactInterval from 'react-interval'
import DefiIntergrations from '../components/intergrations/DefiIntergrations'
import dataIntergrations from '../assets/fake-data/data-intergrations'
import dataIntergrations2 from '../assets/fake-data/data-intergrations'
import partners from '../assets/fake-data/data-intergrations'
import Sale01 from '../components/sale/Sale01'
import Partners from '../components/partners/Partners'
import MessageBar from '../components/messageBar/MessageBar'
import { Helmet } from 'react-helmet'
import Slider from '../components/slider/index'
import arbBanner from '../assets/images/landing-banner.png'
import polyBanner from '../assets/images/poly launch.jpg'
import BannerCarousel from '../components/carousel/Carousel'
const sliderItems = [
  {
    id: 0,
    img: polyBanner,
    title: 'PancakeSwap',
    bgImg: '',
    active: ' ',
    link: 'https://pancakeswap.finance/',
  },
  {
    id: 1,
    img: arbBanner,
    title: 'PancakeSwap',
    bgImg: '',
    active: ' ',
    link: 'https://pancakeswap.finance/',
  },
]

function HomeTwo(props) {
  return (
    <div className="home-3">
      <Helmet>
        <title>xWIN Finance</title>
        <meta
          name="description"
          content="xWin Finance is a decentralized finance (DeFi) that allows individual investors or fund managers to create their own decentralized fund"
        />
      </Helmet>
      {/* <MessageBar show={true} /> */}
      <BannerCarousel props={sliderItems} />
      <Banner03 />
      <Counter />
      <About01 />

      <Services02 data={dataServices2} />

      <Trading data={dataTrading} />

      <DefiIntergrations
        data={dataIntergrations}
        ethData={dataIntergrations2}
      />

      <Partners data={partners} />

      <Faq />

      <Join />
    </div>
  )
}

export default HomeTwo
