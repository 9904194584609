import React from 'react'
import { Link } from 'react-router-dom';


const Partners = (props) => {

    const {partners} = props.data;



  return (
    <section className="intergrations-container" >
                <div className="defi-intergration" style={{display:"flex", justifyContent:"center",textAlign:"center"}}               
>
                <div
                className=""
                data-aos="fade-up"
                data-aos-duration="1000"
                
                >
                    
                <h3 className="heading" style={{  color:"#230b59"}}>Our Partners</h3>
                <p className="fs-20 desc" >
                xWIN Finance is a thriving and growing community.
                Our community trust is our commitment.
                </p>


                <ul className="partners-list">

                    {
                        partners?.map(idx =>(
                        <a href={idx.link} target="_blank">
                            <li key={idx.id} className="">
                                <div className={`icon`}>
                                    <img className='partners-icon ' src={idx.icon} alt="Rockie" />
                                </div>
                                <div className="content">
                                    <h6 className="title" >{idx.title}</h6>
                                 
                                </div>
                            </li>
                        </a>
                        ))
                    }
                    
                </ul>

                </div>
            </div>
    </section>
  )
}

export default Partners