import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import img1 from '../../assets/images/icon/icon-1.png'
import img2 from '../../assets/images/icon/icon-2.png'
import img3 from '../../assets/images/icon/icon-3.png'
import img4 from '../../assets/images/icon/icon-4.png'
import img5 from '../../assets/images/icon/icon-5.png'
import avt from '../../assets/images/avt/user.jpg'
import arrow from '../../assets/images/icon/Arrow.png'
import bitcoin from '../../assets/images/icon/bitcoin.png'
import chart from '../../assets/images/icon/chart-up-2.png'
import SliderOptimiser from './SliderOptimiser';





Services02.propTypes = {
    data: PropTypes.array
};

function Services02(props) {
    
    const {data} = props;

    const [dataBlock] = useState(
        {
            heading: 'How To Use Optimizer?',
            desc : 'Build a portfolio that is one-of-a-kind, just like you'
        }
    )
    const [dataIcon] = useState([
        {
            icon: img1
        },
        {
            icon: img2
        },
        {
            icon: img3
        },
        {
            icon: img4
        },
        {
            icon: img5
        },
    ])

    
    
    return (
        <section className="services-2"  >
        <div className="container" >
            <div className="row"   style={{justifyContent:"center" }}>
                <div className="col-xl-6 col-md-12"               
>
                <div
                className="services__content p-2"
                data-aos="fade-up"
                data-aos-duration="1000"
                >
                <h3 className="heading">{dataBlock.heading}</h3>
                <p className="fs-20 desc" >
                {dataBlock.desc}
                </p>
                <ul className="listt">
                    {
                        data.map(idx =>(
                        <li key={idx.id} className={idx}>
                            <div className={`icon ${idx.bg}`}>
                                <img src={idx.icon} alt="Rockie" />
                            </div>
                            <div className="content">
                                <p className="step" style={{color:"#230b59"}}>{idx.step}</p>
                                <h6 className="title" style={{color:"#230b59"}}>{idx.title}</h6>
                                <p style={{color:"#230b59"}}>
                                    {idx.text}
                                </p>
                            </div>
                        </li>
                        ))
                    }
                    
                </ul>
                </div>
            </div>
            {/* Put the optimiser here */}
            <div style={{ maxWidth:"600px"}} >
           
            <SliderOptimiser />

            </div>
            
            </div>
        </div>
        </section>
    );
}

export default Services02;