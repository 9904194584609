

import img1 from '../images/icon/manager.png'
import img2 from '../images/icon/Lock.png'
import img3 from '../images/icon/smartphone.png'
const dataServices2 = [
    {
        id: 1,
        icon: img1,
        step: 'Step 1',
        title: 'Adjust your risk tolerance',
        text: 'Knowing your age helps us to better understand your investment needs and goals',
        active: '',
        bg:''
    },
    {
        id: 2,
        icon: img2,
        step: 'Step 2',
        title: 'Run the optimizer',
        text: 'We can provide personalized recommendations and insights that are tailored to your specific financial situation with our optimiser',
        active: '',
        bg:'green'
    },
    {
        id: 3,
        icon: img3,
        step: 'Step 3',
        title: 'Build your own vault',
        text: 'Lets us handle the hard stuff and give yourself the peace of mind that comes with knowing your investments are protected and optimized with our smart contracts',
        active: '',
        bg:'blue'
    },
    

   
]

export default dataServices2;