import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";
import 'util';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const root = ReactDOM.createRoot(document.getElementById("root"));

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAimOU59pfu9N9M76LT1C4gQNJK5EMYi7c",
//   authDomain: "xwinstage.firebaseapp.com",
//   projectId: "xwinstage",
//   storageBucket: "xwinstage.appspot.com",
//   messagingSenderId: "1034117015626",
//   appId: "1:1034117015626:web:f3e82cc960ded2a60d9eb3",
//   measurementId: "G-4M4F0WZ2ML"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
root.render(
    <React.Fragment>
        <BrowserRouter>
        <ScrollToTop />
            <App />
        </BrowserRouter>
    </React.Fragment>
);

