import img1 from '../../../src/assets/images/products/irt_icon.png'
import img2 from '../../../src/assets/images/icon/xdca.png'
import img3 from '../../../src/assets/images/icon/xsca.btc.png'
import img4 from '../../../src/assets/images/icon/xdollar.png';

import wallet from "../../assets/images/icon/btcwallet.png"

const dataTrading = [{
        id: 1,
        icon: img1,
        step: '',
        path: '/bitcoin-trading-strategy',
        title: '(BTC) Interest Rate Indicator',
        text: 'The strategy is a better alternative to the buy and hold (BAH) strategy. It aims to create a positive return during a short to mid-term on capital gains. The trades are based on USDT interest rates.',
    },
    {
        id: 2,
        icon: img2,
        step: '',
        path: '/dollar-cost-avarage',
        title: 'Dollar Cost Average',
        text: 'Dollar-cost averaging is a strategy for buying stocks at an average price to smooth out volatility and achieve long-term growth with minimal effort on timing.',
    },
    {
        id: 3,
        icon: img3,
        step: '',
        path: '/stablecoin-alpha',
        title: 'Stablecoin Alpha',
        text: 'This strategy aims to generate positive returns through capital gains by buying assets using stable coin interest rates, protecting the principal amount and providing an alternative to just staking the stable coin.',
    },
    {
        id: 3,
        icon: img4,
        step: '',
        path: '/dollar-zanmai',
        title: 'Dollar Zanmai',
        text: 'This is earn different interest rate from each of the stable coin in the BNB ecosystem. It is hard to time the timing of which stable coin pay higher interests. Therefore, diversifying the stable coin staking may boost the returns of the investment',
    },


]

export default dataTrading;