import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/icon/xdollar.png'
import ReactPlayer from 'react-player';
import {Helmet} from "react-helmet";
import usdtIcon from '../../assets/images/icon/usdt.png'
import usdcIcon from '../../assets/images/icon/usdc.png'
import daiIcon from '../../assets/images/icon/dai.png'
import gmxIcon from '../../assets/images/icon/gmx.png'
import polygonIcon from '../../assets/images/icon/polygon.png'
import arbIcon from '../../assets/images/icon/arb-logo.png'
import Button from '../../components/button';


XWinZanmai.propTypes = {
    
};

function XWinZanmai(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN Dollar Zanmai</title>
                <meta name="description" content="Earning different interests in different stablecoin" />
            </Helmet>

        <PageTitle externalLink={"https://app.xwin.finance/app/funddetailV2/0xFa4d4B4243dDA1F5f4d09269f61D57d02470635C/chainId=56"} img={product_img} heading='Dollar Zanmai Staking (xDollar)' title='xDollar' />

        <section className="blog-details">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdtIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdcIcon} alt="" />}
                                className="p-2" title='BNB' path='https://app.xwin.finance/app/funddetailV2/0xFa4d4B4243dDA1F5f4d09269f61D57d02470635C/chainId=56' />
                                <div className="p-2" >USDC/USDT</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdtIcon} alt="" />}
                                image3={<img width={35} height={35} src={daiIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/funddetailV2/0xEd83FDfaae296674d13672a629A8740724eEeeD7/chainId=42161' />
                                <div className="p-2" >USDC/USDT/DAI</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={usdtIcon} alt="" />}
                                image3={<img width={35} height={35} src={daiIcon} alt="" />}
                                className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0xB4319e7dFBb84C8160846359EbA6d3b9b9440f69/chainId=137' />
                                <div className="p-2" >USDC/USDT/DAI</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                   
                    <div className="content p-2">
                        <p>
                        This is earn different interest rate from each of the stable coin in the BNB ecosystem. It is hard to time the timing of which stable coin pay higher interests. Therefore, diversifying the stable coin staking may boost the returns of the investment

                        Reminder: 50% of your USDT deposited will be swapped into USDC and DAI respectively and then staked into venus pool to earn the interest. Therefore, there might be some slippage and market impact on the exchange rate between USDT-DAI or USDT-USDC

                        </p>

                        <div className="box-image">
                        
                        </div>

                    

                        <h5>Composition</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>USDC</td>
                                    <td style={{width: "164.438px", height: "20px",}}>xUSDC-A</td>
                                    <td style={{width: "376.641px", height: "20px",}}>50.00%</td>
                                    </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>USDT</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>xUSDT-A</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                    </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>DAI</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>xDAI-A</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                </tr>
                            </tbody>
                        </table>



                        </p>
                       

                        <div className="box-image">
                        
                        </div>

                        <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                        <ul >
                            <p>This strategy is suitable for investors who:</p>
                            <p>• have low risk appetite</p>
                            <p>• are seeking for long capital growth</p>
                            <p>• are seeking to make investment slowly and risk-free.</p>
                        </ul>

                        <div className="box-image">
                            
                        </div>
      
                    </div>

                    
                    </div>
                </div>
                
                </div>
            </div>
        </section>        
    </div>
    );
}

export default XWinZanmai;