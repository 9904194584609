import React from 'react'
import { Link } from 'react-router-dom';


const DefiIntergrations = (props) => {

    const {dataIntergrations, dataIntergrations2} = props.data;


  return (
    <section className="intergrations-container" >
                <div className="defi-intergration" style={{display:"flex", justifyContent:"center",textAlign:"center"}}               
>
                <div
                className=""
                data-aos="fade-up"
                data-aos-duration="1000"
                
                >
                    
                <h3 className="heading" style={{  color:"#230b59"}}>DeFi Integrations</h3>
                <p className="fs-20 desc" >
                xWIN Finance simplifies your investment experience by providing hassle-free trading strategies.
                xWIN Finance integrated with all the protocols in the ecosystem.
                </p>

                <h6 className="heading" style={{ marginTop:"50px" ,color:"#230b59"}}>Binance Smart Chain</h6>

                <ul className="intergration-list">

                    {
                        dataIntergrations.map(idx =>(
                        <a href={idx.link} target="_blank">
                            <li key={idx.id} className="">
                                <div className={`icon`}>
                                    <img className='intergrations-icon ' src={idx.icon} alt="Rockie" />
                                </div>
                                <div className="content">
                                    <h6 className="title" >{idx.title}</h6>
                                 
                                </div>
                            </li>
                        </a>
                        ))
                    }
                    
                </ul>

                <h6 className="heading" style={{ marginTop:"50px" ,color:"#230b59"}}>Arbitrum / Polygon Chain</h6>


                <ul className="intergration-list">

                    {
                        dataIntergrations2.map(idx =>(
                        <a href={idx.link} target="_blank">
                            <li key={idx.id} className="">
                                <div className={`icon`}>
                                    <img className='intergrations-icon floating' src={idx.icon} alt="Rockie" />
                                </div>
                                <div className="content">
                                    <h6 className="title" style={{marginTop:"15px"}} >{idx.title}</h6>
                                 
                                </div>
                            </li>
                        </a>
                        ))
                    }
                    
                </ul>
                </div>
            </div>
    </section>
  )
}

export default DefiIntergrations