import React, { useState } from "react";
import PropTypes from "prop-types";
import "./banner03.css";

import Button from "../button";
import img from "../../assets/images/layout/banner-03.png";

import image from "../../assets/images/BannerImage/xwin_chart.jpeg";

Banner03.propTypes = {};

function Banner03(props) {
   const [dataBlock] = useState({
      title: "Designed for simplicity in investing",
      desc: "Our cutting-edge technology simplifies the investment process, allowing you to make informed decisions with ease.",
   });

   return (
      <div className="banner">
         <div className="container">
            <div className="col-md-12">
               <div className="Grid">
                  <div className="banner__content">
                     <h2 className="title" >{dataBlock.title}</h2>
                     <p className="fs-20 desc" style={{marginBottom:"60px"}}>
                        {dataBlock.desc}
                     </p>
                     <p className="fs-20 desc" style={{marginBottom:"30px"}}>
                        {"To kick start your journey, you can use TransFi on-ramp and off-ramp services to exchange your fiat currency into USDT for BNB network or USDCE for Arbitrum One"}
                     </p>
                  </div>
                  <div className="TransFi">
                     <iframe title="TransFi Ramp Widget" src="https://buy.transfi.com/?apiKey=jErEyB6iTkFlmBoG" frameborder="no" allowtransparency="true" allowfullscreen={true} style={{ display: "block" }}></iframe>
                     <div>
                     <p style={{ fontSize:"11px", color: "#230b59", marginTop: "20px" }}><i>Note: xWIN Finance do not provide fiat-to-crypto onramp and offramp service but just facilating our users to use Transfi<br/>
                     Please read and ensure you understand the services and fee structure in <a target="_blank" href="https://www.transfi.com/about">
                     Transfi</a></i></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <br />
      </div>
   );
}

export default Banner03;
