import React , {useState} from 'react';
import PropTypes from 'prop-types';

import img from '../../assets/images/layout/join.png'

import {Link} from 'react-router-dom';
import Button from '../button';

Join.propTypes = {
    
};

function Join(props) {

    const [dataBlock] = useState(
        {
            title: 'Join our telegram community group',
            desc : "Are you ready to join the cutting edge of the decentralized finance revolution? Our lively community of crypto enthusiasts is always eager to welcome new members, and we're excited to have you join us."
        }
    );
    return (
        <section className="join">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="join__images p-2">
                    <img src={img} alt="" />
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div
                    className="join__content p-2"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading">{dataBlock.title}</h3>
                    <p className="desc">
                        {dataBlock.desc}
                    </p>
                    <div className='right'>
                    <Button title='Join now' path='https://www.t.me/xwinfinance' />
                    </div>
                    
                </div>
                </div>

            </div>
         </div>

           
        </section>
    );
}

export default Join;