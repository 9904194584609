import Carousel from 'react-bootstrap/Carousel'
import {
  Breakpoint,
  BreakpointProvider,
  setDefaultBreakpoints,
} from 'react-socks'

setDefaultBreakpoints([
  { xs: 0 },
  { s: 376 },
  { m: 426 },
  { l: 769 },
  { xl: 1225 },
  { xxl: 1425 },
])

function BannerCarousel(props) {
  return (
    <Carousel>
      {props?.props?.map((prop) => (
        <Carousel.Item>
          <div className="">
            <BreakpointProvider>
              <Breakpoint xxl up>
                <img
                  src={prop.img}
                  style={{
                    width: '100%',
                    paddingLeft: '30rem',
                    paddingRight: '30rem',
                    background: '#cfe2ff',
                  }}
                />
              </Breakpoint>
              <Breakpoint xl only>
                <img
                  src={prop.img}
                  style={{
                    width: '100%',
                    paddingLeft: '15rem',
                    paddingRight: '15rem',
                    background: '#cfe2ff',
                  }}
                />
              </Breakpoint>
              <Breakpoint l only>
                <img
                  src={prop.img}
                  style={{
                    width: '100%',
                    paddingLeft: '15rem',
                    paddingRight: '15rem',
                    background: '#cfe2ff',
                  }}
                />
              </Breakpoint>
              <Breakpoint m down>
                <img
                  src={prop.img}
                  style={{
                    width: '100%',
                    background: 'white',
                  }}
                />
              </Breakpoint>
            </BreakpointProvider>
          </div>

          {/* <img
            src={prop.img}
            style={{
              width: '100%',
              paddingLeft: '15rem',
              paddingRight: '15rem',
              background: 'white',
            }}
          /> */}
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default BannerCarousel
