import React, { useState, useEffect } from 'react'
import '../counter/counter.css'
import icon1 from '../../assets/images/icon/icon-c1.png'
import icon2 from '../../assets/images/icon/icon-c2.png'
import AdaIcon from '../../assets/images/icon/ada.png'
import ArbIcon from '../../assets/images/icon/arb-logo.png'
import BNBIcon from '../../assets/images/icon/bnb.png'
import polygonIcon from '../../assets/images/icon/polygon.png'
import axios from 'axios'
import Button from '../button'

Counter.propTypes = {}

function Counter(props) {
  const [v2TvlPlus, setV2TvlPlus] = React.useState('')
  const [adaTvl, setAdaTvl] = React.useState('')
  const [arbTvl, setArbTvl] = React.useState('')
  const [polyTvl, setPolyTvl] = React.useState('')
  const [tvl, setTvl] = useState('')

  const [dataBlock] = useState({
    heading: 'We are growing',
    desc:
      'Across all Vaults and xWIN Farming Pools in Arbitrum and BNB network',
    descOthers: 'Legacy Vaults BNB network and ADA Cardano Pool',
  })
  useEffect(() => {
    getTVL()
    getAdaTVL()
    newApiData()
  }, [])

  const newApiData = async () => {
    const res = await axios.get(
      'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalLockValue',
    )
    setArbTvl(parseInt(res?.data?.ARB))
    setPolyTvl(parseInt(res?.data?.Polygon))
    setV2TvlPlus(parseInt(res.data?.BNB))
  }

  const getTVL = async () => {
    const res = await axios.get(
      'https://us-central1-xwininvestment.cloudfunctions.net/getTotalLockValueV2',
    )
    setTvl(parseInt(res.data))
  }

  const getAdaTVL = async () => {
    const res = await axios.get(
      'https://js.cexplorer.io/api-static/pool/pool1ak3vhtv7alve9wfj5kx5xswrtfvy5a23txuupgy6nqpduq350w5.json',
    )
    const adaPrice = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd',
    )

    const { stake_active } = res.data.data
    const formattedNumber = (
      (stake_active * adaPrice.data.cardano.usd) /
      1000000
    ).toLocaleString('en-US', { maximumFractionDigits: 0 })

    setAdaTvl(formattedNumber)
  }

  return (
    <section className="counter-numbers">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text center">
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="desc">{dataBlock.desc}</p>
            </div>
            <div
              className="counter-mainn"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img className="img" src={icon1} alt="" />
              <ul
                className="list-counter counter-scroll"
                style={{ justifyContent: 'center' }}
              >
                <li style={{}}>
                  <p className="title">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.20996L12 6.80996L16.5 4.20996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 19.79V14.6L3 12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12L16.5 14.6V19.79"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.27002 6.95996L12 12.01L20.73 6.95996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22.08V12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <a href="https://app.xwin.finance" target="_blank">
                      Arbitrum TVL
                    </a>
                  </p>
                  <div className="number-content counter">
                    <span
                      className="timer count-number countup number"
                      data-value="20"
                    >
                      <img width={35} height={35} src={ArbIcon} alt="" /> $
                      {arbTvl.toLocaleString('en-US')}
                    </span>
                  </div>
                </li>
                <li>
                  <p className="title">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.20996L12 6.80996L16.5 4.20996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 19.79V14.6L3 12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12L16.5 14.6V19.79"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.27002 6.95996L12 12.01L20.73 6.95996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22.08V12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <a href="https://app.xwin.finance" target="_blank">
                      BNB TVL
                    </a>
                  </p>
                  <div
                    className="number-content counter"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      className="timer count-number countup number"
                      data-value="20"
                    >
                      <img width={35} height={35} src={BNBIcon} alt="" /> $
                      {v2TvlPlus.toLocaleString('en-US')}
                    </span>
                  </div>
                </li>
                <li>
                  <p className="title">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.20996L12 6.80996L16.5 4.20996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 19.79V14.6L3 12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12L16.5 14.6V19.79"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.27002 6.95996L12 12.01L20.73 6.95996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22.08V12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <a href="https://app.xwin.finance" target="_blank">
                      Polygon TVL
                    </a>
                  </p>
                  <div
                    className="number-content counter"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      className="timer count-number countup number"
                      data-value="20"
                    >
                      <img width={35} height={35} src={polygonIcon} alt="" /> $
                      {polyTvl.toLocaleString('en-US')}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div className="block-text center">
              <p className="desc">{dataBlock.descOthers}</p>
            </div>
            <div className="counter-mainn">
              <img className="img" src={icon1} alt="" />
              <img className="img" src={icon2} alt="" />
              <ul
                className="list-counter counter-scroll"
                style={{ justifyContent: 'center' }}
              >
                <li style={{}}>
                  <p className="title">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.20996L12 6.80996L16.5 4.20996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 19.79V14.6L3 12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12L16.5 14.6V19.79"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.27002 6.95996L12 12.01L20.73 6.95996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22.08V12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <a href="https://v1.xwin.finance" target="_blank">
                      Legacy TVL
                    </a>
                  </p>
                  <div className="number-content counter">
                    <span
                      className="timer count-number countup number"
                      data-value="20"
                    >
                      <img width={35} height={35} src={BNBIcon} alt="" /> $
                      {tvl.toLocaleString('en-US')}
                    </span>
                  </div>
                </li>
                <li>
                  <p className="title">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.20996L12 6.80996L16.5 4.20996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 19.79V14.6L3 12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12L16.5 14.6V19.79"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.27002 6.95996L12 12.01L20.73 6.95996"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22.08V12"
                        stroke="#777E90"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <a
                      href="https://xwin.finance/app/dashboard"
                      target="_blank"
                    >
                      Cardano Staking TVL
                    </a>
                  </p>
                  <div
                    className="number-content counter"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      className="timer count-number countup number"
                      data-value="20"
                    >
                      <img width={35} height={35} src={AdaIcon} alt="" /> $
                      {adaTvl.toLocaleString('en-US')}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="docs-section">
              <div>
                <Button title="Launch" path="https://app.xwin.finance" />
              </div>
              <div>
                <Button
                  title="Docs"
                  path="https://xwinfinance.gitbook.io/xwin-docs/master"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Counter
