import React from 'react'
import HomeThree from "./HomeThree";
import Faq from "./Faq";
import XWinIRT from "./productPages/XWinIRT";
import XWinDCA from "./productPages/XWinDCA";
import XWinSCA from "./productPages/XWinSCA";
import XWinSA from "./productPages/XWinSA";
import XWinZanmai from "./productPages/XWinDollarZanmai";
import About from "./About";
import Xwinfinance from "./productPages/Xwinfinance";
import XWinIndex from './productPages/XWinIndex';
import XWinRDCA from './productPages/XWinRDCA';



const routes = [
  { path: '/', component: <HomeThree />},
  // { path: '/home-v2', component: <HomeTwo />},
  // { path: '/home-v3', component: <HomeThree />},
  { path: '/faq', component: <Faq />},
  { path: '/bitcoin-trading-strategy', component: <XWinIRT />},
  { path: '/dollar-cost-avarage', component: <XWinDCA />},
  { path: '/reverse-dollar-cost-average', component: <XWinRDCA />},
  { path: '/dollar-zanmai', component: <XWinZanmai />},
  { path: '/stablecoin-alpha', component: <XWinSCA />},
  { path: '/crypto-staking', component: <XWinSA />},
  { path: '/index-fund', component: <XWinIndex />},
  { path: '/crypto-robo-advisor', component: <About />},
  { path: '/Xwinfinance', component: <Xwinfinance />},
  
  


]

export default routes;