import React , {useState} from 'react';
import PropTypes from 'prop-types';
import "../../components/trading/trading.css"
import { Link } from 'react-router-dom';



Trading.propTypes = {
    data: PropTypes.array
};

function Trading(props) {


    const {data} = props;

    const [dataBlock] = useState(
        {
            heading: 'Strategies for you',
            desc : 'xWIN Finance has a variety of products that make it the best place to start investing'
        }
    )
    return (
        <section className="trading p-2">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12" >
                    <coingecko-coin-list-widget  coin-ids="bitcoin,ethereum,xwin-finance,cardano,solana,binancecoin,tether,usd-coin,binance-usd,ripple,dogecoin,matic-network,avalanche-2,pancakeswap-token"  currency="usd" locale="en"></coingecko-coin-list-widget>     
                </div>

                <div className="col-xl-6 col-md-12">
                    <div
                    className="trading__content p-2"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading m-3">{dataBlock.heading}</h3>
                    <p className="desc fs-20 p-2">
                        {dataBlock.desc}
                    </p>

                    <ul className="list-steps" >
                        {
                            data.map(idx => (
                                <Link  to={idx.path}><li key={idx.id} className={idx.active}>
                                    
                                    <img src={idx.icon} />

                                    <div className="content">
                                        <p className="step">{idx.step}</p>
                                            <h6 className="title">{idx.title}</h6>
                                        <p className="fs-16" 
                                        style={{fontWeight:'normal', fontSize:"16px" }}
                                        >
                                            {idx.text}
                                        </p>
                                    </div>
                                </li></Link>
                            ))
                        }
                        
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Trading;