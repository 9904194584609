import React from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import dataFaq from '../assets/fake-data/data-faq'


Faq.propTypes = {
    
};

function Faq(props) {
    return (
        <div className='intergrations-container'>
            <section className="faq">
            <div className="container">
                <div className="row" >
                <div className="col-md-12" >
                    <div className="block-text center p-2">
                    <h3 className="heading">Frequently Asked Questions</h3>
                    <p className="desc fs-20">Learn how to get started</p>
                    </div>
                </div>
                <div className="col-md-12" >
                    <div className="flat-accordion p-2"  >
                        {
                            dataFaq.map(idx => (
                                <Accordion key={idx.id} title={idx.title} show={idx.show} >
                                    <p className="toggle-content">{idx.text}</p>
                                </Accordion>
                            ))
                        }
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
    );
}

export default Faq;