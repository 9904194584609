import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import ReactPlayer from 'react-player';
import product_img from '../../assets/images/logo/favicon.png'
import {Helmet} from "react-helmet";



Xwinfinance.propTypes = {
    
};

function Xwinfinance(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN Finance</title>
                <meta name="description" content="xWin Finance is a decentralized finance (DeFi) that allows individual investors or fund managers to create their own decentralized fund" />
            </Helmet>
            <div>
            
            <PageTitle externalLink={"https://app.xwin.finance/app/dashboard"} img={product_img} heading='xWin Finance' title='xWIN Finance v2' />
            </div>
            <section className="blog-details">
                <div className="container">
                    <div className="d-flex p-2 justify-content-center col-xl-12">
                        <ReactPlayer
                            url='https://xwinfinance.s3.amazonaws.com/xWin+Finance+V2.mp4#t=1'
                            width='100%'
                            height='100%'
                            controls={true}
                            loop
                        />
                    </div>
                    
                    <div className="box-image"></div>
                </div>
            </section>
        </div>
    );
}

export default Xwinfinance;