import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import {Helmet} from "react-helmet";
import usdtIcon from '../../assets/images/icon/usdt.png'
import usdcIcon from '../../assets/images/icon/usdc.png'
import indexIcon from '../../assets/images/icon/majorIndexBSC.png'
import xPIVIcon from '../../assets/images/icon/xpiv.png'
import defiIcon from '../../assets/images/icon/defi.png'
import Button from '../../components/button';


XWinIndex.propTypes = {
    
};

function XWinIndex(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN Index Funds</title>            
            </Helmet>

        <PageTitle img={indexIcon} externalLink={"https://app.xwin.finance/app/funddetailV2/0x0A0817454710102F2bcB2215D616cBe3aFf495e5/chainId=56"} heading='Major Index Fund' title='Index Funds' />

        <section className="blog-details">  
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdtIcon} alt="" />}
                                image2={<img width={35} height={35} src={indexIcon} alt="" />}
                                className="p-2" title='BSC' path='https://app.xwin.finance/app/funddetailV2/0x0A0817454710102F2bcB2215D616cBe3aFf495e5/chainId=56' />
                                <div className="p-2" >BSC Major Index</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={indexIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/funddetailV2/0x5b0D58149CbD7f041a8833f8EfE4b2DdADAc47D2/chainId=42161' />
                                <div className="p-2" >Arbitrum Major Index</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={xPIVIcon} alt="" />}
                                className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0xC1c53b4A16b834599B1F6acfd869765B08d422ac/chainId=137' />
                                <div className="p-2" >Polygon Major Index</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                   
                    <div className="content p-2">
                        <p>
                        Index funds have been a safer and easier way for many investors to invest in. 
                        With so many tokens to choose from it can be difficult for investors to figure out which tokens to buy and how much. 
                        These index funds are a great way for investors to get exposure to the market without having to pick and choose tokens.
                        xWin Finance offers index funds for each chain allowing investors to not have to switch chains.
                        </p>

                        <div className="box-image">
                        
                        </div>

                    
                        <h5>Composition</h5>
                        <h5>BSC</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Bitcoin</td>
                                    <td style={{width: "164.438px", height: "20px",}}>BTC</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                    </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>Ethereum</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>ETH</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                    </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>WBNB</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>WBNB</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>ADA</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>ADA</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        </p>

                        <h5>Arbitrum</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Bitcoin</td>
                                    <td style={{width: "164.438px", height: "20px",}}>BTC</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Ethereum</td>
                                    <td style={{width: "164.438px", height: "20px",}}>ETH</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>Arbitrum</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>ARB</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                    </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>GMX</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>GMX</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>25.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        </p>

                        <h5>Polygon</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Bitcoin</td>
                                    <td style={{width: "164.438px", height: "20px",}}>BTC</td>
                                    <td style={{width: "376.641px", height: "20px",}}>20.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>Ethereum</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>ETH</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>20.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>Balancer</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>BAL</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>20.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>UNISWAP</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>UNI</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>20.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>Chainlink</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>LINK</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>20.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        </p>
                    </div>                   
                    </div>
                </div>
                
                </div>
            </div>
        </section>

        <PageTitle img={defiIcon} externalLink={"https://app.xwin.finance/app/funddetailV2/0x61d5722290F8755b2f31D260064658D6Ad837F37/chainId=56"} heading='Chain Specific Index Fund' title='Index Funds' />

        <section className="blog-details">  
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button
                                image1={<img width={35} height={35} src={usdtIcon} alt="" />} 
                                image2={<img width={35} height={35} src={defiIcon} alt="" />}
                                className="p-2" title='BNB' path='https://app.xwin.finance/app/funddetailV2/0x61d5722290F8755b2f31D260064658D6Ad837F37/chainId=56' />
                                <div className="p-2" >BSC Defi Index</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 p-2">
                        <div className="content p-2" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />} 
                                image2={<img width={35} height={35} src={defiIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/funddetailV2/0xc9F5559fAf40c515932A2A18aE1d676af83A0221/chainId=42161' />
                                <div className="p-2" >Arbitrum Emerging Index</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                
                    <div className="content p-2">
                        <p>These index funds are blockchain-specific meaning that the funds have allocations into tokens that are only available in the specific chain. 
                            These index funds offer a way for investors to invest in these tokens but also the chain itself. 
                            When a specific blockchain becomes more popular, the tokens in the index funds will increase in its price.
                        </p>

                        <div className="box-image">
                        </div>
                        <h5>Composition</h5>
                        <h5>BSC xWIN DEFI Index</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Pancakeswap</td>
                                    <td style={{width: "164.438px", height: "20px",}}>CAKE</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>xWin Finance</td>
                                    <td style={{width: "164.438px", height: "20px",}}>XWIN</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Biswap</td>
                                    <td style={{width: "164.438px", height: "20px",}}>BSW</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>VENUS</td>
                                    <td style={{width: "164.438px", height: "20px",}}>XVS</td>
                                    <td style={{width: "376.641px", height: "20px",}}>25.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        </p>

                        <h5>Arbitrum Emerging Index</h5>
                        <p>
                        
                        <table border='1'>
                            <tbody>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "306.875px", height: "20px",}}>Asset</td>
                                    <td style={{width: "164.422px", height: "20px",}}>Symbol</td>
                                    <td style={{width: "376.594px", height: "20px",}}>Weight %</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "20px",}}>Arbitrum</td>
                                    <td style={{width: "164.438px", height: "20px",}}>ARB</td>
                                    <td style={{width: "376.641px", height: "20px",}}>34.00%</td>
                                </tr>
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>GMX Finance</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>GMX</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>33.00%</td>
                                </tr> 
                                <tr style={{height: "20px"}}>
                                    <td style={{width: "206.891px", height: "18.4px",}}>RDNT</td>
                                    <td style={{width: "164.438px", height: "18.4px",}}>RDNT</td>
                                    <td style={{width: "376.672px", height: "18.4px",}}>33.00%</td>
                                </tr>
                            </tbody>
                        </table>
                        </p>

                        <div className="box-image">
                        
                        </div>

                        <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                        <ul >
                            <p>This strategy is suitable for investors who:</p>
                            <p>• have high risk appetite.</p>
                            <p>• seeking for gaining main exposures in crypto currrencies.</p>
                            <p>• seeking to gain exposure without holding many tokens.</p>
                        </ul>

                        <div className="box-image">
                            
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
        </section>
        
             
    </div>
    );
}

export default XWinIndex;