import React , { useState , useEffect } from 'react';

import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import bnbIcon from '../../assets/images/coin/bnb.png'
import arbIcon from '../../assets/images/coin/arb.png'

import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo.png';
import DarkMode from './DarkMode';

import Button from '../button';
import { useMediaQuery } from 'react-responsive';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from '@material-ui/core';


const Header = () => {

    const [openSub, setOpenSub] = React.useState([]);
    const subTextColor = "black"

    const handleClick = (idx, open) => event => {
        event.preventDefault()
        if(open === undefined) open = false
        var obj  = {}
        obj[idx] = !open
        setOpenSub(obj);
    };

    const useStyles = makeStyles({
        drawer :{
            width:"200px",

        },
        drawerPaper:{
            width:"220px",
            // backgroundImage: `linear-gradient(to right, #c0c6f0, #f4b897, #fdf3ed)`,
            display:"flex",
            flexDirection:"column",
            paddingTop:"60px",
            backgroundColor:"#536dfe",
            color:"white",
            fontSize:"26px"

        }
    })

        const isMobile = useMediaQuery({ maxWidth: 767 });


      const [isOpen, setIsOpen] = useState(false);

 
    
  
      const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setIsOpen(!isOpen);
      };
    
   


    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    
    const getLink = (data) => {

        if(!data.external){
            return <Link href={data.links} to={data.links}>{data.name}</Link>
        }else{
            return <a className={classes.externalLink} href={data.links} target='new'>{data.name}</a>
        }
    }

    const getSubLink = (submenu, external) => {

        if(!external){
            return <NavLink to={submenu.links}>
                 <Typography align='justify' fontSize="small">{submenu.sub}</Typography> 
                </NavLink>
        } 
        else if(external && submenu.icon1 === undefined){
            return <a href={submenu.links} target='new'>
                <div className="icon" >
                <Typography align='justify' fontSize="small">{submenu.sub}</Typography> 
                </div>
            </a>
        }else{
            return <a href={submenu.links} target='new'>
                {submenu.icon1 !== undefined &&
                <>
                <div className="icon">
                    <img width={'35'} height={'35'} src={submenu.icon1} alt="Rockie" />
                    <img style={{marginRight:"20px"}} width={'20'} height={'20'} src={bnbIcon} alt="USDT-USDC.e" />
                    <img width={'35'} height={'35'} src={submenu.icon2} alt="Rockie" />
                    <img style={{marginRight:"20px"}} width={'20'} height={'20'} src={arbIcon} alt="USDC-USDC.e" />
                </div>
                <div className="icon" >
                <Typography align='justify' fontSize="small">{submenu.sub}</Typography> 
                </div>
                </>
                }
               
                </a>
        }
    }

    const getMobileLink = (data) => {
        if(!data.external){
            return <Link to={data.links}>
                    <ListItem fontSize="small" style={{paddingLeft:"32px", paddingTop:"32px"}}>
                         {data.name} 
                     </ListItem>
                </Link>
        }else{
            return <a className={classes.externalLink} href={data.links} target='new'>
                <ListItem fontSize="small" style={{paddingLeft:"32px", paddingTop:"20px"}}>
                         {data.name} 
                     </ListItem>
                </a>
        }
    }

    const displayMenu = (idx, data) => {

            return (
                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} >
                {getLink(data)}
                {
                    data.namesub &&
                    <ul className="sub-menu">
                        {
                            data.namesub.map((submenu) => (
                                <li key={submenu.id} className="menu-item">
                                     {getSubLink(submenu, data.external)}   
                                </li>
                            ))
                        }
                    </ul>
                }
                </li>
            )
    }

    const displayMobileMenu = (idx, data) => {

        if(data.namesub === undefined){
            
            return (
                getMobileLink(data)
            )
        }else{
            return displayMobileSubMenu(data, idx)
        }
        
    }
    
    const displayMobileSubMenu = (data, idx) => {

        if(data === undefined) return
        return(
            <>
                <ListItemButton onClick={handleClick(idx, openSub[idx])}>
                    <ListItem style={{fontWeight:"bold", fontSize:"16px"}} >
                        {data.name}
                    </ListItem>
                    {    
                    openSub[idx] ? <ExpandLess /> : <ExpandMore />
                    }
                </ListItemButton>
                <Collapse in={openSub[idx]} timeout="auto" unmountOnExit >
                <li key={idx} className={"px-4"} >
                    <ul className="sub-menu">
                    {
                        data.namesub.map((submenu) => (
                            <li key={submenu.id} className="menu-item">
                                    {getSubLink(submenu, data.external)}   
                            </li>
                        ))
                    }
                    </ul>
                </li>
                </Collapse>
            </>
        )
        
    }

    const classes = useStyles()

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                <div className="col-12">
                    <div className="header__body d-flex justify-content-between">
                    <div className="header__left">
                        <div className="logo">
                        <NavLink to='/' className="light">
                            <img
                            src={logo}
                            alt="Rockie"
                            />
                        </NavLink>
                        <NavLink to='/' className="dark">
                            <img className='logo__image__dark'
                            src={logodark}
                            alt="Rockie"
                            />
                        </NavLink>

                        </div>
                       

                        <div className="left__main">
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                <ul id="menu-primary-menu" className="menu">
                                {
                                    menus.map((data,idx) => (
                                        displayMenu(idx, data)    
                                    ))
                                }
                                </ul>
                            </nav>
                            
                 
                        </div>
                    </div>

                    <div className="header__right">

                    { !isMobile &&
                        <Button className="launch__button" title='Launch App'  path="https://app.xwin.finance" />

                    }

                    {isMobile &&
                        <>
                            
                            <MenuIcon  fontSize="large"  color="inherit" aria-label='logo' onClick={toggleDrawer(true)}></MenuIcon>
                            <Drawer
                                anchor="left" 
                                open={isOpen}
                                onClose={()=>{setIsOpen(false)}}
                                classes={{paper : classes.drawerPaper}}
                                // style={{color:{subTextColor}}}
                            >
                                <List style={{flexDirection:"column"}}>
                                    {
                                        menus.map((data,idx) => (
                                            displayMobileMenu(idx, data)    
                                        ))
                                    }
                                    <Link style={{fontSize:"16px"}} onClick={()=>setIsOpen(!isOpen)}>
                                        <ListItem style={{paddingLeft:"32px"}}  button onClick={()=>window.open("https://app.xwin.finance")}>
                                            Launch App
                                        </ListItem>
                                    </Link>
                                </List>
                            </Drawer>
                        </>
                        
                        }
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <DarkMode />
        </header>
       
    );
}

export default Header;