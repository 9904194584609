import React , {useState} from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/products/sa_btc_icon.png'
import {Helmet} from "react-helmet";


XWinSA.propTypes = {
    
};

function XWinSA(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN's new and unique USDT staking strategy</title>
                <meta name="description" content="This is a strategy to allow investors to stake in their assets so that the assets stake would return an interest" />
            </Helmet>
            <div>
                <PageTitle externalLink={"https://app.xwin.finance/app/strategies/singlebase"} img={product_img} heading='Lending Vault' title='Lending Vault' />
            </div>
        <section className="blog-details">
            <div className="container">
                {/* <div className="d-flex p-2 justify-content-center col-xl-12">
                        <ReactPlayer
                            url='https://xwinfinance.s3.amazonaws.com/xWIN-DCA.m4v'
                            width='100%'
                            height='100%'
                            controls={true}
                            loop
                        />
                    </div> */}
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                    
                    <div className="content p-2">
                        <h5>TRADING STRATEGY OBJECTIVE</h5>
                        <p>
                
                        This is a strategy to allow investors to stake in their assets so that the assets stake would return an interest. 
                        It is more benefitial if you are holding xUSDT-v than USDT because xUSDT-v token gains interest through lending protocol in Venus Finance.
                        Investor can combine both similar lending vault in different allocation weight to achieve optimal returns from lending.
                        
                        </p>

                        <div className="box-image">
                        
                        </div>

                    

                        <h5>TRADING STRATEGY DESCRIPTION</h5>
                        <p>
                        Lending vault allows investors to lend their assets like USDT and ETH to markets like Ola Finance or Venus Finance, this way it earns interest by putting the asset to work.
                         The interest rate varies with the market. They can change every day or even just hours, all depending on the supply of the assets.
                          Interest rates can also range from high 30% to low as 1%. As these stakings have no locking period, they can be withdrawn at any time when the investor chooses to. 





                        </p>
                        

                        <div className="box-image">
                        
                        </div>

                        <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                        <ul >
                            <p>This strategy is suitable for investors who:</p>
                            <p>• have low risk appetite</p>
                            <p>• are seeking to get more from their assets</p>
                            <p>• are seeking to gain exposure to the token while earning the interests</p>
                        </ul>

                        <div className="box-image">
                            
                        </div>


                    </div>

                    </div>
                </div>
                
                </div>
            </div>
        </section>
    </div>
    );
}

export default XWinSA;