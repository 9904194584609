

import img1 from '../images/icon/show.png'
import img2 from '../images/icon/done.png'
import img3 from '../images/icon/users.png'
const dataAbout = [
    {
        id: 1,
        title: 'How do I use xWIN Robo Advisor?', 
        text: "To use xWIN Robo Advisor, there are two steps: First, Run the optimizer to get the token allocation. Then, Click 'build' and create your vault by signing your transaction. This will deploy the xWIN fundv2 smart contract to the network, allowing you to access your private fund under the 'My Menu' section.",
        show: 'show'
    },
    {
        id: 2,
        title: 'Do I need to pay everytime when I rebalance my portfolio?',
        text: 'No. You are free to use the optimizer and rebalance at no cost. However, there is a initial small amount of cost at 10 XWIN when you create your own vault by deploying your smart contract in xWIN Finance platform.',
    },
    {
        id: 3,
        title: 'What the Mean-Variance Optimization?',
        text: 'Mean-Variance Optimization uses mean-variance analysis, where it is the process of weighing risk, expressed as variance, against expected return. Investors weigh how much risk they are willing to take on in exchange for different levels of reward. Mean-variance analysis allows investors to find the biggest reward at a given level of risk or the least risk at a given level of return.',
    },
    {
        id: 4,
        title: 'Are there any other asset allocation approach provided?',
        text: 'We are currently provided only Weight Average SR and Mean-Variance Optimization. Our team is working toward introducing more approach such as Maximum Sharpe Ratio, Minimum Variance Portfolio or Inverse Variance Weighted Portfolio and etc etc in near future',
    }
   
]

export default dataAbout;