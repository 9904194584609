import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/products/irt_icon.png'
import ReactPlayer from 'react-player';
import {Helmet} from "react-helmet";


XWinIRT.propTypes = {
    
};

function XWinIRT(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN unique bitcoin trading strategy</title>
                <meta name="description" content="Interest Rate Trading (IRT) is xWIN's proprietary bitcoin trading strategy. Trading is based on the USDT interest rate and aims to create positive returns over the short to medium term." />
            </Helmet>

            <PageTitle externalLink={"https://app.xwin.finance/app/strategydetailv2/0x5A8a66DF53DF88844c60829967b88d00eD208E08"} img={product_img} heading='Interest Rate Trading (IRT)' title='IRT' />

            <section className="blog-details">
                <div className="container">
                    
                <div className="d-flex p-2 justify-content-center col-xl-12">
                        <ReactPlayer
                            url='https://xwinfinance.s3.amazonaws.com/xWIN-IRT.mp4#t=1'
                            width='100%'
                            height='100%'
                            controls={true}
                            loop
                        />
                    </div>
                    <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="blog-main">
                        <div className="content p-2">
                            <h5>TRADING STRATEGY OBJECTIVE</h5>
                            <p>
                    
                                The strategy is a better alternative to the buy and hold (BAH) strategy. It aims to create a positive return during a short to mid-term on capital gains. The trades are based on USDT interest rates.


                            </p>

                            <div className="box-image">
                            
                            </div>

                        

                            <h5>TRADING STRATEGY DESCRIPTION</h5>
                            <p>
                            The strategy depends on the indicator of USDT's deposit rate which is a strong indicator of where the market is at. We believe that a high deposit rate indicates the market is overleveraged while a low deposit rate may signal a underleverage market. The strategy makes the comparison of current day interest rate with the benchmark moving average. Each day’s interest rate if calculated by taking the average of the 24 hours.
                            The strategy usually holds trades for weeks but can differ on the market. The strategy has been found to hold trades for a day to months depending on the market.


                            </p>
                           
                            <div className="box-image">
                            
                            </div>

                            <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                            <ul >
                                <p>This strategy is suitable for investors who:</p>
                                <p>• have high risk appetite</p>
                                <p>• are seeking for short to mid-term capital growth</p>
                                <p>• are seeking to do investments that require little to no work (passive income)</p>
                            </ul>

                            <div className="box-image">
                                
                            </div>

                        </div>

                        </div>
                    </div>
                    
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default XWinIRT;