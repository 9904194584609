import React , {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import img1 from '../../assets/images/layout/about-h1.png'
import icon1 from '../../assets/images/icon/icon-01.png'
import icon2 from '../../assets/images/icon/icon-02.png'
import icon3 from '../../assets/images/icon/icon-03.png'
import icon4 from '../../assets/images/icon/icon-04.png'
import icon5 from '../../assets/images/icon/icon-05.png'
import image from "../../assets/images/BannerImage/2.png"



import { Pagination, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Button from '../button';
import { textAlign } from '@mui/system';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss' ;
import videoThumbnail from "../../assets/images/blog/robo_thumbnail.png"

import ReactPlayer from 'react-player'





About01.propTypes = {
    
};

function About01(props) {
    const [data] = useState([
        {
            id: 1,
            img : img1,
        },
        {
            id: 2,
            img : img1,
        },
        {
            id: 3,
            img : img1,
        },
        {
            id: 4,
            img : img1,
        },
    ])
    const [icon] = useState([
        {
            id: 1,
            img : icon1,
            class: 'icon-1'
        },
        {
            id: 2,
            img : icon2,
            class: 'icon-2'
        },
        {
            id: 3,
            img : icon3,
            class: 'icon-3'
        },
        {
            id: 4,
            img : icon4,
            class: 'icon-4'
        },

        {
            id: 5,
            img : icon5,
            class: 'icon-5'
        },
    ])

    const [dataBlock] = useState(
        {
            heading: 'What is xWIN Robo Advisor?',
            desc : 'The objective of this xWIN Robo Advisor is to allow individual investors or fund managers to create their own decentralized fund based on various market factors or personal risk parameters. xWIN Robo Advisor is built with an in-house optimizer engine, which allows investors to create the optimal portfolio through mean-variance efficient or weighted average Sharpe Ratio. ',
        }
    )
    const [dataList] = useState([
        {
            title: 'Mean Variance Optimization',
            text: ' Mean-variance analysis is a method used to determine the optimal allocation of assets in a portfolio using the factors provided including risk tolerance and age. Mean-variance analysis helps investors create the optimal combination of assets by maximizing the expected returns for a given level of risk'
        },
        {
            title: 'Weighted-Average Sharpe Ratio',
            text: 'The approach will first allocate the major allocation into stable coin staking asset based on the risk tolerances as parameter. Finally, the remaining weights will be allocated based on the Sharpe ratio weight of each risky token.'
        },
    ]
    )

    const [isOpen, setOpen] = useState(false);
    const [youtubeId, setYoutubeId]= useState("");

    return (
        <section className="about" style={{backgroundColor:"red"}}>
            <div className="container">
                <div className="row">
                    
                <div className="col-xl-6 col-md-12" style={{display:"flex",justifyContent:"center" ,}}>        
                            
                    
                    

                        <div className="blog-box s1 p-2" style={{ display:"flex", justifyContent:"center", alignItems:"center",}}>
                               

                            <ReactPlayer
                                    url='https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2'
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                    // playing
                                    loop
                                    muted
                                />

                        </div>

                        

                </div>
                <div className="col-xl-6 col-md-12" >
                    <div
                    className="p-2 about__content"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 class="p-2" className="heading">{dataBlock.heading}</h3>
                    <p class="p-2" className="fs-20 desc">
                        {dataBlock.desc}
                    </p>
                    <ul className="list">

                        {
                            dataList.map((data,idx)=> (
                                <li key={idx}>
                                    <h6 class="p-2 m-1" className="title" style={{color:"#230b59"}}>
                                        <span className="icon-check"></span>{data.title}
                                    </h6>
                                    <div class="p-2 m-1" className="text" style={{color:"#230b59"}}>
                                    {data.text}
                                    </div>
                                </li>
                            ))
                        }

                    </ul>

                    </div>
                </div>
                </div>
                
            </div>


        </section>
    );
}

export default About01;