import React from 'react';
import 'react-modal-video/scss/modal-video.scss' ;
import PageTitle from '../../components/pagetitle';
import product_img from '../../assets/images/icon/xdca.png'
import ReactPlayer from 'react-player';
import {Helmet} from "react-helmet";
import btcIcon from '../../assets/images/icon/bitcoin.png'
import maticIcon from '../../assets/images/icon/matic.png'
import gmxIcon from '../../assets/images/icon/gmx.png'
import polygonIcon from '../../assets/images/icon/polygon.png'
import arbIcon from '../../assets/images/icon/arb-logo.png'
import usdtIcon from '../../assets/images/icon/usdt.png'
import usdcIcon from '../../assets/images/icon/usdc.png'

import Button from '../../components/button';


XWinDCA.propTypes = {
    
};

function XWinDCA(props) {

    return (
        <div>
            <Helmet>
                <title>xWIN Dollar Cost Averaging Strategy</title>
                <meta name="description" content="xWIN's Dollar Cost Average (DCA) is a token that automatically staked USDT by earning interest, while executes to swap USDT into BTC every day to achieve dollar cost average effect. The strategy is suitable for avoiding high price buying of cryptocurrencies such as BTC, which fluctuate in price." />
            </Helmet>
            <div>
            
            <PageTitle externalLink={"https://app.xwin.finance/app/strategydetailv2/0x482ae949E4a70953fCa090717b68359b73b8602a/chainId=56"} img={product_img} heading='Dollar Cost Average (DCA)' title='DCA' />
            </div>
            <section className="blog-details">
                <div className="container">
                <div className="row">
                    <div className="d-flex p-2 justify-content-center col-xl-6 col-md-6">
                            <ReactPlayer
                                url='https://xwinfinance.s3.amazonaws.com/xWIN-DCA.m4v#t=4'
                                width='100%'
                                height='100%'
                                controls={true}
                                loop
                            />
                    </div>

                    <div className="col-xl-6 col-md-6 p-2">
                        <div className="content" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdtIcon} alt="" />}
                                image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                title='BNB' path='https://app.xwin.finance/app/strategydetailv2/0x482ae949E4a70953fCa090717b68359b73b8602a/chainId=56' />
                                <div className="p-2" >USDT/BTC</div>
                            </div>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/strategydetailv2/0x5834542ee7B6FE644Cd568E3ad48A5B0D58EC8ba/chainId=42161' />
                                
                                <div className="p-2" >USDC/BTC</div>
                            </div>
                        </div>                        
                        <div className="content" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={gmxIcon} alt="" />}
                                className="p-2" title='Arbitrum' path='https://app.xwin.finance/app/strategydetailv2/0x9cCD1D6c0992c57d064745B8261CC7d5FBad8c80/chainId=42161' />
                               
                                <div className="p-2" >USDC/GMX</div>
                            </div>
                            <div className="p-2" ><Button 
                                image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                image2={<img width={35} height={35} src={maticIcon} alt="" />}
                                className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0x99c04716ec9285d4057765022183E2e05D51a2C6/chainId=137' />
                                
                                <div className="p-2" >USDC/MATIC</div>
                            </div>
                        </div>
                        <div className="content" style={{display:"flex", justifyContent:"center",textAlign:"center"}}>
                            <div className="p-2" >
                                <Button 
                                    image1={<img width={35} height={35} src={usdcIcon} alt="" />}
                                    image2={<img width={35} height={35} src={btcIcon} alt="" />}
                                    className="p-2" title='Polygon' path='https://app.xwin.finance/app/strategydetailv2/0x99c04716ec9285d4057765022183E2e05D51a2C6/chainId=137' />
                                
                                <div className="p-2" >USDC/BTC</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
                <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="blog-main">
                    
                    <div className="content p-2">
                        <p>
                        The dollar-cost average has been a trading strategy used in the stock market to allow investors to buy stocks at an average price.
                            As the price of BTC and other assets are very volatile, it is a great strategy for leveling the cost so that it does not buy high.
                            The strategy is aimed at long-term growth with little to no work on when to invest.
                        </p>

                        <div className="box-image">
                        
                        </div>

                    

                        <h5>TRADING STRATEGY DESCRIPTION</h5>
                        <p>
                        The strategy buys assets like BTC over a period of time. The strategy will buy each day at the same time to get the average price of the assets.
                            This is continued for months to even years depending on the principal amount. This way we are never buying all the assets at once in order to avoid buying at a high price.
                            As BTC and other cryptocurrencies are volatile, the dollar cost average allows us to level the price and ensure that we do not lose too much but at the same time do not miss out on the large gains.



                        </p>
                        

                        <div className="box-image">
                        
                        </div>

                        <h5>INVESTOR PROFILE / RISK LEVEL</h5>
                        <ul >
                            <p>This strategy is suitable for investors who:</p>
                            <p>• have medium risk appetite</p>
                            <p>• are seeking for long-term capital growth</p>
                            <p>• are seeking go into the market through a safer option</p>
                        </ul>

                        <div className="box-image">
                            
                        </div>
                    </div>

                    </div>
                </div>
                
                </div>
                </div>
            </section>
        </div>
    );
}

export default XWinDCA;